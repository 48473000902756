<template>
  <b-container fluid class="mb-6 mt-6 p-0">
    <b-row>
      <b-col>
        <b-overlay :show="loading">
          <div class="card card-custom gutter-b">
            <div class="mt-0 dataTables_wrapper">
              <v-data-table
                :headers="headers"
                :items="data"
                item-key="id"
                :group-desc="true"
                group-by="group"
                :sort-desc="true"
                :items-per-page="10"
                class="systems-parameter"
                hide-default-footer
              >
                <!-- :items-per-page="-" -->
                <template v-slot:header.name="{ header, items }">
                  <v-icon @click.stop="expandAll"
                    >mdi-{{
                      isExpandedNew
                        ? 'unfold-more-horizontal'
                        : 'unfold-less-horizontal'
                    }}
                  </v-icon>
                  {{ header.text }}
                </template>

                <template
                  v-slot:group.header="{
                    toggle,
                    group,
                    isOpen,
                    headers,
                    items,
                  }"
                >
                  <td class="pl-0 no-background">
                    <div class="d-flex justify-content-between">
                      <div>
                        <v-btn
                          @click="toggle"
                          :ref="group"
                          :data-open="isOpen"
                          icon
                        >
                          <v-icon
                            >mdi-{{
                              isOpen ? 'chevron-down' : 'chevron-up'
                            }}</v-icon
                          >
                        </v-btn>

                        <span class="font-weight-boldest"
                          >{{ $moment(group * 1000).format('DD/MM/YYYY') }}
                        </span>
                      </div>
                    </div>
                  </td>

                  <td>
                    <span class="summary">
                      {{ summaryNumber(items, 'duration') }}
                      <span class="unit">phút</span>
                    </span>
                  </td>
                  <td>
                    <span class="summary">
                      {{ summaryNumber(items, 'burnedCalorie') }}
                      <span class="unit">kcal</span>
                    </span>
                  </td>
                  <td></td>
                </template>
                <template v-slot:item="{ item, expand, isExpanded }">
                  <tr>
                    <td>{{ item.name }}</td>
                    <td>
                      {{ item.duration }}
                      <span class="unit">phút</span>
                    </td>
                    <td>
                      {{ Math.round(item.burnedCalorie) }}
                      <span class="unit">kcal</span>
                    </td>
                    <td colspan="1">{{ item.note }}</td>
                    <!-- <td></td> -->
                  </tr>
                </template>
              </v-data-table>

              <pagination :paging="paging" />
            </div>
          </div>
        </b-overlay>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Paging from '../../../../components/table/pagination';
export default {
  name: 'ExerciseTable',
  components: {
    pagination: Paging,
  },
  props: {
    time: {
      type: Object,
    },
    idUser: {
      type: [String, Number],
    },
  },
  data() {
    return {
      currentPage: 1,
      loading: false,
      options: {},
      isExpandedNew: true,
      isExpandedOld: true,
      headers: [
        {
          text: 'Ngày giờ nhập / Môn vận động',
          value: 'name',
          sortable: false,
        },
        { text: 'Thời gian', value: 'duration', sortable: false },
        { text: 'Số kcal', value: 'kcal', sortable: false },
        { text: 'Ghi chú', value: 'note', sortable: false },
      ],
      data: [],
      paging: {
        page: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  watch: {
    paging: {
      handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      deep: true,
    },
    time: {
      handler(newVal) {
        if (newVal) {
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  computed: {
    searchParams() {
      return {
        currentDateTime: parseInt(new Date().getTime() / 1000),
        page: this.paging.page,
        periodFilterType: this.time.value,
        size: this.paging.pageSize,
      };
    },
    id() {
      return this.idUser;
    },
  },
  methods: {
    handlePageChange() {},
    async loadData() {
      this.loading = true;
      try {
        let { data, meta } = await this.$api.get(`Admin/Exercise/Input`, {
          params: { ...this.searchParams, patientId: this.id },
        });
        const payload = data
          .map((ex) => {
            return ex.exerciseInput.map((e) => ({
              group: ex.date,
              date: this.$moment(ex.date * 1000).format('DD/MM/YYYY'),
              note: ex.note,
              burnedCalorie: e.burnedCalorie,
              unit: e.unit,
              name: e.exercise[0]?.category,
              duration: e.exercise[0]?.duration,
            }));
          })
          .reduce((a, b) => a.concat(b), []);
        this.data = payload || [];

        this.paging.total = meta.total;
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
      return;
    },
    summaryNumber(items, key) {
      return items.reduce((acc, item) => acc + Number(item[key]), 0);
    },
    expandAll() {
      this.isExpandedNew = !this.isExpandedOld;
      // Filter vue-components
      Object.keys(this.$refs)
        .filter((e) => this.$refs[e]?.$el)
        .forEach((k) => {
          // Check if element has already open
          const status = this.$refs[k].$attrs['data-open'];
          if (status != this.isExpandedNew) {
            this.$refs[k].$el.click();
          }
          return;
        });
      this.isExpandedOld = this.isExpandedNew;
    },
  },
};
</script>

<style lang="scss" scoped>
.unit {
  color: #b5b5c3;
}
.summary {
  color: #21a567;
  font-weight: bold;
}
</style>
